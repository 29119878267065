/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        var headerSize = function() {
          var scrollTop = $(window).scrollTop();
          if(scrollTop > 50) {
            $('body').addClass('st-small-header');
          } else {
            $('body').removeClass('st-small-header');
          }
        };

        headerSize();

        $(window).on('scroll resize', function() {
          headerSize();
        });

        $('.js-stick-in-parent').stick_in_parent({
          offset_top: 130
        });

        var modal = function() {

          $('.js-open-modal').on('click', function(e) {
            e.preventDefault();
            var target = '#' + $(this).attr('data-modal');
            $(target).css('display', 'flex').hide().fadeIn(200);
          });

          $('.js-close-modal').on('click', function() {
            $(this).closest('.js-modal').fadeOut(200);
          });

        };

        modal();

        var ajaxModal = function() {
          var $button = $('.js-open-ajax-modal');
          var $modal = $('.js-modal');
          var $modal_target = $('#js-modal-body');
          $button.on('click', function(e) {
            e.preventDefault();
            $modal_target.html('<div class="u-loader-wrapper js-loader"><div class="u-lds-ring"><div></div><div></div><div></div><div></div></div></div>');
            $modal.css('display', 'flex').hide().fadeIn(200);
            var id = $(this).attr('data-id');
            $.ajax({
              url: ajax_params.ajax_url,
              data: {
                'action': 'fetch_modal_content',
                'id': id
              },
              success: function(data) {
                $modal_target.fadeOut(200, function() {
                  $modal_target.html(data).fadeIn(200);
                });
              }
            });
            return false;
          });
        };

        ajaxModal();

        ScrollReveal().reveal('.js-reveal-left', {
          interval: 100,
          origin: 'right',
          distance: '15px',
          duration: 1000,
        });

        ScrollReveal().reveal('.js-reveal-left-2', {
          interval: 100,
          origin: 'right',
          distance: '15px',
          duration: 1000,
        });

        ScrollReveal().reveal('.js-reveal-left-3', {
          interval: 100,
          origin: 'right',
          distance: '15px',
          duration: 1000,
        });

        ScrollReveal().reveal('.js-reveal-right', {
          interval: 100,
          origin: 'left',
          distance: '15px',
          duration: 1000,
        });

        ScrollReveal().reveal('.js-reveal-right-2', {
          interval: 100,
          origin: 'left',
          distance: '15px',
          duration: 1000,
        });

        ScrollReveal().reveal('.js-reveal-right-3', {
          interval: 100,
          origin: 'left',
          distance: '15px',
          duration: 1000,
        });

        ScrollReveal().reveal('.js-reveal-top', {
          origin: 'bottom',
          interval: 100,
          distance: '15px',
          duration: 1000,
        });

        ScrollReveal().reveal('.js-reveal-top-2', {
          origin: 'bottom',
          interval: 100,
          distance: '15px',
          duration: 1000,
        });

        ScrollReveal().reveal('.js-reveal-fade', {
          interval: 100,
          duration: 1000,
        });

        $('.js-filter-toggle').on('click', function(e) {
          e.preventDefault();
          if (window.matchMedia('(max-width: 959px)').matches) {
            $(this).toggleClass('is-open');
            if($(this).is('.is-open')) {
              $(this).siblings('.js-filter-group').css('display', 'flex').hide().slideDown(250);
            } else {
              $(this).siblings('.js-filter-group').slideUp(250);
            }
          }
        });

        // Close modal when a click is made elsewhere on the page
        /*
        $(document).on('click', function(e) {
          if ($('.js-modal').is(':visible')) {
            modalArea = $(e.target).closest($('.js-modal-box')).length;
            if(!modalArea) {
              $('.js-modal').fadeOut(200);
            }
          }
        }); */

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {

        $('#js-hero-slider')
        .on('init', function(event, slick) {
          $('#js-hero-slider .slick-current').addClass('st-animate');
        })
        .on('beforeChange', function(event, slick, currentSlide, nextSlide) {
          if (nextSlide == 0) {
            $('#js-hero-slider [data-slick-index="'+slick.slideCount+'"]').addClass('st-animate');
          } else {
            $('#js-hero-slider [data-slick-index="'+slick.slideCount+'"]').removeClass('st-animate');
          }
          if (nextSlide == (slick.slideCount - 1)) {
            $('#js-hero-slider [data-slick-index="-1"]').addClass('st-animate');
          } else {
            $('#js-hero-slider [data-slick-index="-1"]').removeClass('st-animate');
          }
          $('#js-hero-slider [data-slick-index="'+nextSlide+'"]').addClass('st-animate');
          setTimeout(function() {
            $('#js-hero-slider [data-slick-index="'+currentSlide+'"]').removeClass('st-animate');
          }, 800);
        })
        .slick({
          infinite: true,
          dots: true,
          autoplay: true,
          autoplaySpeed: 4000,
          speed: 800,
          prevArrow: '#js-hero-slider-nav-left',
          nextArrow: '#js-hero-slider-nav-right',
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'kontakt': {
      init: function() {
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
