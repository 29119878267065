(function($) {

  var mainMenu = function() {

    var $mainMenuContainer = $('#js-main-menu-container');
    var $mainMenuToggle = $('.js-main-menu-toggle');
    var $mainMenu = $('#js-main-menu');

    $mainMenu.attr('aria-expanded', 'false');

    var closeMenu = function() {
      $mainMenuContainer.removeClass('is-toggled');
      $mainMenuToggle.attr('aria-expanded', 'false').removeClass('is-active');
      $mainMenu.attr('aria-expanded', 'false');
    };

    /* Toggle menu */
    $mainMenuToggle.on('click', function(e) {

      e.preventDefault();
      e.stopImmediatePropagation();

      $mainMenuContainer.fadeToggle(250);

      if ($mainMenuContainer.is('.is-toggled')) {
        closeMenu();
      } else {
        $mainMenuContainer.addClass('is-toggled');
        $mainMenuToggle.attr('aria-expanded', 'true').addClass('is-active');
        $mainMenu.attr('aria-expanded', 'true');
      }

    });

  };

  mainMenu();

  var anchorLink = function() {

    $('.js-anchor-link').on('click', function(e) {

      e.preventDefault();
      var section = $(this).attr('href');
      var headerHeight = $('#js-site-header').outerHeight();
      var scrollDistance = $(section).offset().top - headerHeight * 0.72;

      $('html, body').stop().animate({
        scrollTop: scrollDistance
      }, 800);

    });

  };

  anchorLink();

})(jQuery); // Fully reference jQuery after this point.
