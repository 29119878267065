(function($) {
  $(function() {

    $(document).ajaxComplete(function() {
      ScrollReveal().sync();
    });

    var ajaxFilter = function() {
      $('#js-loader-container').addClass('st-loading');
      var filter = $('#js-filter');
      $.ajax({
        url: ajax_params.ajax_url,
        data: filter.serialize(),
        dataType: 'json',
        type: 'POST',
        beforeSend: function(xhr){
        },
        success: function(data){
          $('#js-loader-container').removeClass('st-loading');
          $('#js-filter-response').html(data.content);
        }
      });
      return false;
    };

    $('.js-filter-input').on('change', function(){

      ajaxFilter();

      var query_string;
      if ($('.js-filter-input:checked').not('[name="aeg"]').length > 0) {
        query_string = '?';
        var inputGroups = ['vanusegrupp', 'kategooria', 'liik'];
        for (var i = 0; i < inputGroups.length; i++) {
          var inputName = inputGroups[i];
          if ($('.js-filter-input[name^="'+inputName+'"]:checked').length > 0) {
            var valueArray = [];
            $('.js-filter-input[name^="'+inputName+'"]:checked').each(function() {
              valueArray.push($(this).val());
            });
            valueArray.join(',');
            query_string += inputName + '=' + valueArray + '&';
          }
        }
        query_string = query_string.slice(0, -1);
        window.history.replaceState('', '', query_string);
      } else {
        window.history.replaceState('', '', location.href.split("?")[0]);
      }

      /*
      if($('#js-filter-response').length) {
        var scrollDistance = $('#js-filter-response').offset().top - 20;
        $('html, body').stop().animate({
          scrollTop: scrollDistance
        }, 600);
      } */

    });

  });
})( jQuery );
